import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setEdificioSeleccionado } from './edificioSlice';
import M from 'materialize-css';
import { useNavigate } from 'react-router-dom';

const EdificioIngreso = () => {
    
  const navigate = useNavigate();
  const dispatch=useDispatch();  

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const usuario = useSelector((state) => state.auth.usuario);  
  const edificios = useSelector((state) => state.auth.edificios);  
  const [selectedBuildingId, setSelectedBuildingId] = useState('');
  
  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/');
    }
    M.AutoInit(); // Inicializa los componentes de Materialize CSS
    
    if (edificios && edificios.length > 0) {
      setSelectedBuildingId(edificios[0].codigo);
    }
  }, [usuario, navigate]);


  const handleSelectChange = (e) => {
    setSelectedBuildingId(e.target.value);
  };

  const handleIngresar = () => {
    //console.log("edificios:", JSON.stringify(edificios));
    //console.log("selectedBuildingId:", selectedBuildingId);
    const selectedBuilding = edificios.find(b => b.codigo === selectedBuildingId);

    //console.log("selectedBuilding:", JSON.stringify(selectedBuilding));

    dispatch(setEdificioSeleccionado(selectedBuilding));
    navigate('/dashboard');
  };

  return (
    <div className="container">        
      <div className="row">
        <div className="col s12 center-align">
            <h4>Seleccione</h4>
        </div>
      </div>
      <div className="row">      
            <div className="input-field col s12 m4 offset-m4 center-align">
                <select value={selectedBuildingId} onChange={handleSelectChange}>          
                {edificios.map((building) => (
                    <option key={building.codigo} value={building.codigo}>{building.nombre}</option>
                ))}
                </select>
                <label>Edificio</label>      
            </div>
      </div>
      <div className="row">
        <div className="col s12 m4 offset-m4 center-align">
        <button
            className="btn waves-effect waves-light"
            type="button"
            disabled={!selectedBuildingId}
            onClick={handleIngresar}
            style={{ width: 'auto', padding: '0 30px' }}  // Ajuste del ancho y espaciado
        >
          INGRESAR
        </button>
       </div>
     </div>
    </div>
  );
};

export default EdificioIngreso;
