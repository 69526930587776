import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { get_reuniones_x_edificio, validar_token_asistencia } from './reunionThunk';
import M from 'materialize-css';
import './MisReunionesPage.css';
import { get_asistencias_x_usuario } from './asistenciaThunk';


const MisReunionesPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const reuniones = useSelector((state) => state.reunion.reuniones) || [];
    const usuario = useSelector((state) => state.auth.usuario);
    const status_get_reuniones_x_edificio = useSelector((state) => state.reunion.status_get_reuniones_x_edificio);
    const edificio = useSelector((state) => state.edificio.edificio);
    const asistencias = useSelector((state) => state.asistencia.asistencias) || [];
    const error_get_reuniones_x_edificio = useSelector((state) => state.reunion.error_get_reuniones_x_edificio);
    const unidad_facturacionSeleccionado = useSelector((state) => state.unidad_facturacion.unidad_facturacionSeleccionado);
    

    const [filtro, setFiltro] = useState('');
    const [selectedReunion, setSelectedReunion] = useState(null);
    const [token, setToken] = useState(['', '', '', '']);
    const [isValidating, setIsValidating] = useState(false); // Indicador de validación en curso
    const tokenRefs = [useRef(), useRef(), useRef(), useRef()];

    useEffect(() => {
        if (!isAuthenticated) {
            navigate('/');
        }
    }, [isAuthenticated, navigate]);

    useEffect(() => {
        if (edificio?.id) {
            dispatch(get_reuniones_x_edificio({ buildingId: edificio.id }));
            dispatch(get_asistencias_x_usuario({ buildingId: edificio.id, codigo_usuario: usuario.id }));
        }
    }, [edificio, usuario, dispatch]);

    useEffect(() => {
        if (selectedReunion) {
            M.AutoInit();
        }
    }, [selectedReunion]);

    const handleTokenChange = (index, value) => {
        const updatedToken = [...token];
        const inputValue = value.slice(-1);
        updatedToken[index] = inputValue;
        setToken(updatedToken);

        if (inputValue && index < tokenRefs.length - 1) {
            tokenRefs[index + 1].current.focus();
        }
    };

    const handlePaste = (e) => {
        e.preventDefault();
        const pastedData = e.clipboardData.getData('text').slice(0, 4);
        const updatedToken = pastedData.split('');
        setToken(updatedToken);

        const nextFocusIndex = Math.min(pastedData.length, tokenRefs.length - 1);
        tokenRefs[nextFocusIndex].current.focus();
    };

    const handleValidarAsistencia = async () => {
        const tokenCompleto = token.join('');
        if (tokenCompleto.length !== 4) {
            M.toast({ html: 'El token debe tener 4 dígitos.', classes: 'red' });
            return;
        }

        setIsValidating(true); // Inicia el indicador de carga

        try {
            const asistenciaData = {
                reunionId: selectedReunion.id,
                asunto: selectedReunion.asunto,
                convocante: selectedReunion.convocante,
                fecha_reunion: selectedReunion.fecha_reunion,
                hora_inicio: selectedReunion.hora_inicio,
                modalidad: selectedReunion.modalidad,
                tipo_reunion: selectedReunion.tipo_reunion,
                token: tokenCompleto,
                codigo_usuario: usuario.codigo_usuario,
                usuario: {
                    id: usuario.id,
                    email: usuario.email,
                    titular: usuario.titular,
                },
                buildingId: edificio.id,
                codigo_unidad_facturacion: unidad_facturacionSeleccionado?.codigo_unidad_facturacion,
            };

            await dispatch(validar_token_asistencia({ reunionId: selectedReunion.id, asistenciaData })).unwrap();
            M.toast({ html: 'Asistencia registrada con éxito.', classes: 'green' });
            setToken(['', '', '', '']); // Limpia los inputs del token
            setSelectedReunion(null); // Cierra el modal
        } catch (error) {
            M.toast({ html: error.error || 'Error al validar el token.', classes: 'red' });
        } finally {
            setIsValidating(false); // Detiene el indicador de carga
        }
    };

    const handleFilterChange = (e) => {
        setFiltro(e.target.value.toLowerCase());
    };

    const reunionesFiltrados = reuniones.filter((reunion) => {
        const filter = filtro.trim();
        if (filter === '') return true;

        const tipo_reunion = reunion.tipo_reunion?.toLowerCase() || '';
        const convocante = reunion.convocante?.toLowerCase() || '';
        const fecha_reunion = reunion.fecha_reunion?.toLowerCase() || '';
        const modalidad = reunion.modalidad?.toLowerCase() || '';
        const estado = reunion.estado?.toLowerCase() || '';

        return (
            tipo_reunion.includes(filter) ||
            convocante.includes(filter) ||
            fecha_reunion.includes(filter) ||
            modalidad.includes(filter) ||
            estado.includes(filter)
        );
    });

    const getEstadoAsistencia = (reunion) => {
        const asistencia = asistencias.find((a) => a.reunionId === reunion.id);
        if (reunion.estado === 'Programada' && !asistencia) return 'Pendiente';
        if (reunion.estado === 'Finalizada' && !asistencia) return 'No asistió';
        if (asistencia) return 'Asistió';
        return 'Exonerado'; // En caso quieras incluir un estado de exoneración por reglas adicionales
    };

    return (
        <div className="container mis-reuniones">
            <div className="card">
                <div className="card-content">
                    <span className="card-title">Mis Reuniones</span>
                    <div className="card-action">
                        <button className="btn orange" onClick={() => navigate(`/mis-asistencias`)}>
                            <i className="material-icons left">fingerprint</i>Asistencias
                        </button>
                        <button className="btn orange" onClick={() => navigate(`/mis-justificaciones`)}>
                            <i className="material-icons left">add_box</i>Justificaciones
                        </button>
                        <button className="btn blue" onClick={() => navigate('/dashboard')}>
                            <i className="material-icons left">arrow_back</i>Volver
                        </button>
                    </div>

                    <h5>Listado</h5>

                    <input
                        type="text"
                        value={filtro}
                        onChange={handleFilterChange}
                        placeholder="Filtrar por cualquier campo"
                    />

                    {status_get_reuniones_x_edificio === 'loading' && <p>Cargando reuniones...</p>}

                    {status_get_reuniones_x_edificio === 'succeeded' && reunionesFiltrados.length > 0 && (
                        <table className="striped responsive-table tabla_mis_reuniones_propietario">
                            <thead>
                                <tr>
                                    <th>Tipo</th>
                                    <th>Fecha</th>
                                    <th>Hora</th>
                                    <th>Convocante</th>
                                    <th>Modalidad</th>
                                    <th>Estado</th>
                                    <th>Asistencia</th>
                                    <th>Acción</th>
                                </tr>
                            </thead>
                            <tbody>
                                {reunionesFiltrados.map((reunion) => {
                                    const estadoAsistencia = getEstadoAsistencia(reunion);
                                    return (
                                        <tr key={reunion.id}>
                                            <td>{reunion.tipo_reunion}</td>
                                            <td>
                                                <Link to={`/reunion/view/${reunion.id}`}>{reunion.fecha_reunion}</Link>
                                            </td>
                                            <td>{reunion.hora_inicio}</td>
                                            <td>{reunion.convocante}</td>
                                            <td>{reunion.modalidad}</td>
                                            <td>{reunion.estado}</td>
                                            <td>{estadoAsistencia}</td>
                                            <td>
                                                <button
                                                    className="btn-small blue"
                                                    onClick={() => setSelectedReunion(reunion)}
                                                    disabled={estadoAsistencia === 'Asistió'}
                                                >
                                                    <i className="material-icons">fingerprint</i>
                                                </button>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    )}
                </div>
            </div>

            {selectedReunion && (
                <div className="custom-modal-overlay">
                    <div className="custom-modal">
                        <h4>Asistencia a la reunión</h4>
                        <p>
                            <strong>Fecha:</strong> {selectedReunion.fecha_reunion}
                        </p>
                        <p>
                            <strong>Estado:</strong> {selectedReunion.estado}
                        </p>
                        <div className="token-inputs row" onPaste={handlePaste}>
                            {[0, 1, 2, 3].map((index) => (
                                <div className="input-field col s3" key={index}>
                                    <input
                                        type="text"
                                        maxLength="1"
                                        value={token[index] || ''}
                                        ref={tokenRefs[index]}
                                        onChange={(e) => handleTokenChange(index, e.target.value)}
                                    />
                                </div>
                            ))}
                        </div>
                        <div className="modal-actions">
                            <button
                                className="btn blue"
                                onClick={handleValidarAsistencia}
                                disabled={isValidating} // Deshabilitar mientras está en proceso
                            >
                                {isValidating ? 'Validando...' : 'Validar'}
                            </button>
                            <button
                                className="btn red"
                                onClick={() => {
                                    setSelectedReunion(null);
                                    setToken(['', '', '', '']); // Limpia los inputs al cerrar
                                }}
                            >
                                Cancelar
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default MisReunionesPage;
