// frontend/src/pages/MisFacturaciones_individuales.js
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { get_facturaciones_individuales } from './facturacion_individualThunk';
import M from 'materialize-css';

import { formatDate, formatCurrency, formatPeriod, formatDate02, formatearPeriodo_MesAnio01, formatearImporte01 } from '../../utils/formateo';
import { downloadPDF } from '../../utils/util';

const MisFacturaciones_individuales = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const facturaciones_individuales = useSelector((state) => state.facturacion_individual.facturaciones_individuales) || [];
    const usuario = useSelector((state) => state.auth.usuario);
    const status_get_facturaciones_individuales = useSelector((state) => state.facturacion_individual.status_get_facturaciones_individuales);
    const edificio = useSelector((state) => state.edificio.edificio);
    const error_get_facturaciones_individuales = useSelector((state) => state.facturacion_individual.error_get_facturaciones_individuales);
    const error = useSelector((state) => state.facturacion_individual.error);

    const [filtro, setFiltro] = useState('');

    useEffect(() => {
        if (!isAuthenticated) {
            navigate('/');
            return;
        }

        M.AutoInit(); // Inicializa los componentes de Materialize CSS

        if (status_get_facturaciones_individuales === 'idle' && edificio?.id) {
            console.log("Cargando facturaciones individuales para edificio ID:", edificio.id);
            dispatch(get_facturaciones_individuales(edificio.id));
        }
    }, [isAuthenticated, edificio, status_get_facturaciones_individuales, dispatch]);

    if (!usuario) {
        return null; // Evita renderizar mientras se verifica la autenticación
    }

    const handleFilterChange = (e) => {    
        setFiltro(e.target.value.toLowerCase());
    };

    const facturaciones_individualesFiltrados = facturaciones_individuales.filter((facturacion_individual) => {
        const filter = filtro.trim();
        if (filter === '') return true; // Si el filtro está vacío, mostrar todos

        // Asegurar que los campos existen antes de aplicarle métodos
        const periodo = facturacion_individual.periodo?.toLowerCase() || '';
        const codigo_unidad_facturacion = facturacion_individual.codigo_unidad_facturacion?.toLowerCase() || '';
        const concepto = facturacion_individual.concepto?.toLowerCase() || '';
        const tipo_concepto = facturacion_individual.tipo_concepto?.toLowerCase() || '';
        const tipo_facturacion = facturacion_individual.tipo_facturacion?.toLowerCase() || '';
        const importe = facturacion_individual.importe?.toString().toLowerCase() || '';
        const estado = facturacion_individual.estado?.toLowerCase() || '';

        return (
            periodo.includes(filter) ||
            codigo_unidad_facturacion.includes(filter) ||
            concepto.includes(filter) ||
            tipo_concepto.includes(filter) ||
            tipo_facturacion.includes(filter) ||
            importe.includes(filter) ||
            estado.includes(filter)
        );
    });

    return (
        <div className="container">
            <div className="card">
                <div className="card-content">
                    <span className="card-title">Facturación Individual</span>
                    <div className="card-action">
                        <button 
                            className="btn orange tooltipped" 
                            data-position="top" 
                            data-tooltip="Nueva Facturación Individual" 
                            onClick={() => navigate(`/facturacion-individual/create`)}
                        >
                            <i className="material-icons left">add_box</i>Crear Facturación
                        </button>
                        <button 
                            className="btn blue tooltipped" 
                            data-position="top" 
                            data-tooltip="Volver" 
                            onClick={() => navigate('/dashboard')}
                        >
                            <i className="material-icons left">arrow_back</i>Volver
                        </button>              
                    </div>
                    
                    <h5>Listado</h5>

                    <div className="input-field">
                        <i className="material-icons prefix">search</i>
                        <input 
                            type="text" 
                            id="filtro_tabla_mis_facturaciones_individuales_propietario" 
                            value={filtro}
                            onChange={handleFilterChange} 
                            placeholder="Filtrar por cualquier campo" 
                        />
                    </div>

                    {status_get_facturaciones_individuales === 'loading' && (
                        <div className="progress">
                            <div className="indeterminate"></div>
                        </div>
                    )}

                    {status_get_facturaciones_individuales === 'failed' && (
                        <div className="card-panel red lighten-4 red-text text-darken-4">
                            <span>Error: {error_get_facturaciones_individuales}</span>
                        </div>
                    )}

                    {status_get_facturaciones_individuales === 'succeeded' && facturaciones_individualesFiltrados.length === 0 && (
                        <div className="card-panel yellow lighten-4 yellow-text text-darken-4">
                            <span>No se encontraron facturaciones que coincidan con el filtro.</span>
                        </div>
                    )}

                    {status_get_facturaciones_individuales === 'succeeded' && facturaciones_individualesFiltrados.length > 0 && (
                        <table className="striped responsive-table tabla_mis_facturaciones_individuales_propietario">
                            <thead>
                                <tr>
                                    <th>Periodo</th>
                                    <th>Unid. Fact.</th>
                                    <th>Concepto</th>
                                    <th>Tipo</th>
                                    <th>Facturación</th>
                                    <th>Importe</th>
                                    <th>Estado</th>
                                    <th>Registrador</th>
                                </tr>
                            </thead>
                            <tbody id="table-body-mis-facturaciones_individuales_propietario">                    
                                {facturaciones_individualesFiltrados.map((facturacion_individual) => (
                                    <tr key={facturacion_individual.id}>
                                        <td>{facturacion_individual.periodo}</td>
                                        <td>
                                            <Link to={`/facturacion-individual/view/${facturacion_individual.id}`}>
                                                {facturacion_individual.codigo_unidad_facturacion}
                                            </Link>
                                        </td>
                                        <td>{facturacion_individual.concepto}</td>
                                        <td>{facturacion_individual.tipo_concepto}</td>
                                        <td>{facturacion_individual.tipo_facturacion}</td>
                                        <td>{formatearImporte01(facturacion_individual.importe, facturacion_individual.moneda)}</td>
                                        <td>{facturacion_individual.estado}</td>
                                        <td>{facturacion_individual.usuarioRegistrador}</td>                                        
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>   
            </div> 
        </div>
    );
};

export default MisFacturaciones_individuales;
