// frontend/src/pages/ListaEncuestasPage.js 
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { get_encuestas_x_edificio } from './encuestaThunk';
import M from 'materialize-css';

import { 
    formatDate, 
    formatCurrency, 
    formatPeriod, 
    formatDate02, 
    formatearPeriodo_MesAnio01, 
    formatearImporte01 
} from '../../utils/formateo';
import { downloadPDF } from '../../utils/util';

const ListaEncuestasPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // Selectores del estado de Redux
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const encuestas = useSelector((state) => state.encuesta.encuestas) || [];
    const usuario = useSelector((state) => state.auth.usuario);
    const status_get_encuestas_x_edificio = useSelector((state) => state.encuesta.status_get_encuestas_x_edificio);
    const edificio = useSelector((state) => state.edificio.edificio);
    const error_get_encuestas_x_edificio = useSelector((state) => state.encuesta.error_get_encuestas_x_edificio);
    

    const [filtro, setFiltro] = useState('');

    // Verificar autenticación y redireccionar si no está autenticado
    useEffect(() => {
        if (!isAuthenticated) {
            navigate('/');
        }
    }, [isAuthenticated, navigate]);

    // Inicializar Materialize CSS y cargar encuestas
    useEffect(() => {
        M.AutoInit(); // Inicializa los componentes de Materialize CSS
        //console.log("ANTES status_get_encuestas_x_edificio:", status_get_encuestas_x_edificio);
        
        if (edificio?.id) {
                dispatch(get_encuestas_x_edificio(
                    {
                        buildingId: edificio.id
                    }
                )); 
            //}
        } else {
            console.log("Edificio, edificio.id o ambos no están definidos.");
        }
}, [edificio, dispatch]);

    if (!usuario) {
        return null; // Evita renderizar mientras se verifica la autenticación
    }

    const handleFilterChange = (e) => {    
        setFiltro(e.target.value.toLowerCase());
    };

    const encuestasFiltrados = encuestas.filter((encuesta) => {
        const filter = filtro.trim();
        if (filter === '') return true; // Si el filtro está vacío, mostrar todos

        // Asegurar que los campos existen antes de aplicarle métodos
        const numero_encuesta = encuesta.numero_encuesta?.toLowerCase() || '';
        const titulo = encuesta.titulo?.toLowerCase() || '';
        const fecha_inicio = encuesta.fecha_inicio?.toLowerCase() || '';
        const fecha_fin = encuesta.fecha_fin?.toLowerCase() || '';
        const estado = encuesta.estado?.toLowerCase() || '';

        return (
            numero_encuesta.includes(filter) ||
            titulo.includes(filter) ||
            fecha_inicio.includes(filter) ||
            fecha_fin.includes(filter) ||
            estado.includes(filter)
        );
    });

    return (
        <div className="container">
            <div className="card">
                <div className="card-content">
                    <span className="card-title">Encuestas</span>
                    <div className="card-action">
                        <button 
                            className="btn orange tooltipped" 
                            data-position="top" 
                            data-tooltip="Nuevoa Encuesta" 
                            onClick={() => navigate(`/encuesta/create`)}
                        >
                            <i className="material-icons left">add_box</i>Crear Encuesta
                        </button>
                        <button 
                            className="btn blue tooltipped" 
                            data-position="top" 
                            data-tooltip="Volver" 
                            onClick={() => navigate('/dashboard')}
                        >
                            <i className="material-icons left">arrow_back</i>Volver
                        </button>              
                    </div>
                    
                    <h5>Listado</h5>

                    <div className="input-field">
                        <i className="material-icons prefix">search</i>
                        <input 
                            type="text" 
                            id="filtro_tabla_mis_encuestas_propietario" 
                            value={filtro}
                            onChange={handleFilterChange} 
                            placeholder="Filtrar por cualquier campo" 
                        />
                    </div>

                    {status_get_encuestas_x_edificio === 'loading' && (
                        <div className="progress">
                            <div className="indeterminate"></div>
                        </div>
                    )}

                    {status_get_encuestas_x_edificio === 'failed' && (
                        <div className="card-panel red lighten-4 red-text text-darken-4">
                            <span>Error: {error_get_encuestas_x_edificio}</span>
                        </div>
                    )}

                    {status_get_encuestas_x_edificio === 'succeeded' && encuestasFiltrados.length === 0 && (
                        <div className="card-panel yellow lighten-4 yellow-text text-darken-4">
                            <span>No se encontraron encuestas que coincidan con el filtro.</span>
                        </div>
                    )}

                    {status_get_encuestas_x_edificio === 'succeeded' && encuestasFiltrados.length > 0 && (
                        <table className="striped responsive-table tabla_mis_encuestas_propietario">
                            <thead>
                                <tr>
                                    <th>Nro.</th>
                                    <th>Titulo</th>
                                    <th>Inicio</th>
                                    <th>Fin</th>
                                    <th>Estado</th>
                                    <th>Registrador</th>
                                </tr>
                            </thead>
                            <tbody id="table-body-mis-encuestas_propietario">                    
                                {encuestasFiltrados.map((encuesta) => (
                                    <tr key={encuesta.id}>                                        
                                        <td>{encuesta.numero_encuesta}</td>
                                        <td>
                                            <Link to={`/encuesta/view/${encuesta.id}`}>
                                            {encuesta.titulo}
                                            </Link>
                                        </td>                                        
                                        <td>{encuesta.fecha_inicio}</td>
                                        <td>{encuesta.fecha_fin}</td>
                                        <td>{encuesta.estado}</td>
                                        <td>{encuesta.usuarioRegistrador || 'N/A'}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>   
            </div> 
        </div>
    );
};

export default ListaEncuestasPage;
