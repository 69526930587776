import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { get_plantillas_activo_x_edificio } from './plantilla_activoThunk';
import M from 'materialize-css';
import { Link } from 'react-router-dom';


const ListaPlantillasActivoPage = () => {

  const navigate = useNavigate();

  const dispatch = useDispatch();
  //const user = useSelector((state) => state.usuario.user);

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const plantillas_activo = useSelector((state) => state.plantilla_activo.plantillas_activo);
  const usuario = useSelector((state) => state.auth.usuario);
  const fetchStatus = useSelector((state) => state.plantilla_activo.status_get_plantillas_activo_x_edificio);
  const edificio = useSelector(state => state.edificio.edificio);
  const error = useSelector((state) => state.plantilla_activo.error_get_plantillas_activo_x_edificio);
  const [filtro, setFiltro] = useState('');

  //console.log("en mis plantillas_activo isAuthenticated: "+isAuthenticated);
  useEffect(() => {
    if (!isAuthenticated) {
      //console.log("no existe usuario");
      navigate('/');
    }
    M.AutoInit(); // Inicializa los componentes de Materialize CSS
    //console.log("fetchStatus: "+ fetchStatus);


  }, [navigate]);

  useEffect(() => { 
    //console.log("fetchStatus: "+ fetchStatus);
    console.log("fetchStatus " + fetchStatus + " edificio.buildingId: " + edificio.id);
    if (edificio.id) {
      console.log("entro y va a llamar a get_plantillas_activo ");
      dispatch(get_plantillas_activo_x_edificio(edificio.id));

    }


  }, [ edificio]);

  if (!usuario) {
    return null; // Return null while redirecting
  }

  /*
    const formatearRecibos = (plantillas_activo) => {
      return plantillas_activo.map(plantilla_activo => ({
        ...plantilla_activo,
        fecha_emision_formato01: formatDate(plantilla_activo.fecha_emision),
        fecha_corte_formato01: formatDate(plantilla_activo.fecha_corte),
        periodo: formatPeriod(plantilla_activo.fecha_emision),
        importe_recibo_formato01: formatCurrency(plantilla_activo.importe_recibo),
      }));
    };
  */

  //const plantillas_activoFormateados = formatearRecibos(plantillas_activo);

  const handleFilterChange = (e) => {
    setFiltro(e.target.value);

  };

  //const plantillas_activoFiltrados = plantillas_activoFormateados.filter((plantilla_activo) => {
  const plantillas_activoFiltrados = Array.isArray(plantillas_activo) ? plantillas_activo.filter((plantilla_activo) => {
    //console.log("CHECK  JSON.stringify(plantilla_activo): " + JSON.stringify(plantilla_activo));
    //const fechaCreacionFormateado = formatDate02(plantilla_activo.fechaCreacion);

    const filterLowerCase = filtro.toLowerCase();

    return plantilla_activo.codigo_plantilla?.toLowerCase().includes(filterLowerCase) ||
      plantilla_activo.codigo_activo?.toLowerCase().includes(filterLowerCase) ||
      plantilla_activo.nombre_activo?.toLowerCase().includes(filterLowerCase) ||
      plantilla_activo.tipo?.toLowerCase().includes(filterLowerCase) ||
      plantilla_activo.categoria?.toLowerCase().includes(filterLowerCase) ||
      plantilla_activo.subcategoria?.toLowerCase().includes(filterLowerCase) ||
      plantilla_activo.ubicacion?.toLowerCase().includes(filterLowerCase)
      ;
  })
    : [];


  if (fetchStatus === 'loading') {
    return <div>Loading...</div>;
  }

  if (fetchStatus === 'failed') {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="container">
      <div className="card">
        <div className="card-content">
          <span className="card-title">Plantillas de Activos</span>
          <div className="card-action">

            <a className="btn orange tooltipped" data-position="top" data-tooltip="Nuev Activo" onClick={() => navigate('/plantilla_activo/create')}>
              <i className="material-icons left">add_box</i>Crear Plantilla
            </a>
            <a className="btn blue tooltipped" data-position="top" data-tooltip="Volver" onClick={() => navigate('/dashboard')}>
              <i className="material-icons left">arrow_back</i>
            </a>
          </div>


          <h5>Listado</h5>
          <div className="col s12 m12 input-field">
            <label htmlFor="filtro_tabla_mis_plantillas_activo_propietario"><i className="material-icons">search</i> Filtrar</label>
            <input type="text" id="filtro_tabla_mis_plantillas_activo_propietario"
              value={filtro}
              onChange={handleFilterChange} />
          </div>
          <table className="striped responsive-table tabla_mis_plantillas_activo_propietario">
            <thead>
              <tr>
                <th>Plantilla</th>
                <th>Codigo</th>
                <th>Nombre</th>
                <th>Tipo</th>
                <th>Categoría</th>
                <th>Subcategoría</th>
                <th>Ubicación</th>
              </tr>
            </thead>
            <tbody id="table-body-mis-plantillas_activo_propietario">
              {plantillas_activoFiltrados.map((plantilla_activo) => (
                <tr key={plantilla_activo.id}>
                  <td>
                    <Link to={`/plantilla_activo/view/${plantilla_activo.id}`}>
                      {plantilla_activo.codigo_plantilla}
                    </Link>
                  </td>
                  <td>{plantilla_activo.codigo_activo}</td>
                  <td>{plantilla_activo.nombre_activo}</td>
                  <td>{plantilla_activo.tipo}</td>
                  <td>{plantilla_activo.categoria}</td>
                  <td>{plantilla_activo.subcategoria}</td>
                  <td>{plantilla_activo.ubicacion}</td>
                </tr>
              ))}

            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ListaPlantillasActivoPage;